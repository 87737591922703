define("ember-svg-jar/inlined/icon-svn", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<style/><path d=\"M4.9 21.9H24s4.9 0 5.9-5.3c1-4.8-3.1-8-7.1-8 0 0-1-5.7-6-5.7-6.5 0-6.8 4.7-6.8 4.7s-3.4-.8-5.5 1.3-1 4.9-1 4.9S.7 15.6 1 17.7s1 3.7 3.9 4.2z\" fill=\"none\" stroke=\"#9d9d9d\" stroke-width=\"1.2\" stroke-miterlimit=\"10\"/><g fill=\"#9d9d9d\"><path d=\"M5.4 18.4c.5.3 1.2.6 2 .6 1.1 0 1.8-.6 1.8-1.5 0-.8-.5-1.3-1.6-1.7-1.4-.5-2.3-1.2-2.3-2.4 0-1.3 1.1-2.3 2.8-2.3.9 0 1.5.2 1.9.4l-.3.9c-.3-.2-.9-.4-1.7-.4-1.2 0-1.6.7-1.6 1.3 0 .8.5 1.2 1.7 1.7 1.5.6 2.2 1.3 2.2 2.5 0 1.3-1 2.5-3 2.5-.8 0-1.7-.2-2.2-.5l.3-1.1zM13.7 19.8l-2.8-8.6h1.2l1.3 4.2c.4 1.2.7 2.2.9 3.2.2-1 .6-2.1 1-3.2l1.5-4.2H18l-3.1 8.6h-1.2zM18.9 19.8v-8.6h1.2l2.8 4.3c.6 1 1.1 1.9 1.5 2.8-.1-1.1-.1-2.2-.1-3.5v-3.6h1v8.6h-1.1l-2.7-4.4c-.6-1-1.2-1.9-1.6-2.9.1 1.1.1 2.1.1 3.5v3.7h-1.1z\"/></g>",
    "attrs": {
      "version": "1.1",
      "id": "icon-svn_svg__Layer_1",
      "xmlns": "http://www.w3.org/2000/svg",
      "x": "0",
      "y": "0",
      "viewBox": "0 0 31.2 25.5",
      "xml:space": "preserve"
    }
  };
  _exports.default = _default;
});