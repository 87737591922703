define("ember-g-recaptcha/components/g-recaptcha", ["exports", "ember-g-recaptcha/configuration"], function (_exports, _configuration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['g-recaptcha'],
    sitekey: _configuration.default.siteKey,
    tabindex: Ember.computed.alias('tabIndex'),
    renderReCaptcha: function renderReCaptcha() {
      var properties = this.getProperties('sitekey', 'theme', 'type', 'size', 'tabindex', 'hl', 'badge');
      var parameters = Ember.assign(properties, {
        callback: this.get('successCallback').bind(this),
        'expired-callback': this.get('expiredCallback').bind(this)
      });
      var widgetId = window.grecaptcha.render(this.get('element'), parameters);
      this.set('widgetId', widgetId);
      this.set('ref', this);
      this.renderCallback();
    },
    resetReCaptcha: function resetReCaptcha() {
      if (Ember.isPresent(this.get('widgetId'))) {
        window.grecaptcha.reset(this.get('widgetId'));
      }
    },
    renderCallback: function renderCallback() {
      var action = this.get('onRender');
      if (Ember.isPresent(action)) {
        action();
      }
    },
    successCallback: function successCallback(reCaptchaResponse) {
      var action = this.get('onSuccess');
      if (Ember.isPresent(action)) {
        action(reCaptchaResponse);
      }
    },
    expiredCallback: function expiredCallback() {
      var action = this.get('onExpired');
      if (Ember.isPresent(action)) {
        action();
      } else {
        this.resetReCaptcha();
      }
    },
    appendScript: function appendScript(src) {
      var scriptTag = document.createElement('script');
      scriptTag.src = src;
      document.body.appendChild(scriptTag);
    },
    // Lifecycle Hooks
    didInsertElement: function didInsertElement() {
      var _this = this;
      this._super.apply(this, arguments);
      window.__ember_g_recaptcha_onload_callback = function () {
        _this.renderReCaptcha();
      };
      var baseUrl = _configuration.default.jsUrl || 'https://www.google.com/recaptcha/api.js?render=explicit';
      this.appendScript("".concat(baseUrl, "&onload=__ember_g_recaptcha_onload_callback"));
    }
  });
  _exports.default = _default;
});